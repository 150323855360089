import React, { useState, useEffect } from 'react';
import { SmartStorage } from '@/utils';
import commonService from '@/service/common';
import ShowMicroApp from './compments/showMicroApp';
import './index.less';

const components = {
    ShowMicroApp,
};

const DetailPage = () => {
    // 模块数组
    const [modules, setModules] = useState([]);
    const getDetailMoudles = () => {
        let moudleParams = {
            collection: 'cfg-web-modular',
            filter: {
                Application: 'XCENTER',
                tenantId: '*',
                type: 'mop',
            },
        };
        commonService.GetSettings(moudleParams).then((res) => {
            if (res && res.success) {
                setModules(res.content.modules);
            }
        });
    };
    useEffect(() => {
        getDetailMoudles();
    }, []);
    return (
        <div className="detailPage">
            {modules?.map((item, index) => {
                const TagName = components[item.comName];
                return (
                    <TagName
                        key={index}
                        requestVal={SmartStorage.get('detailRow')}
                        microData={item.microData}
                        moduleItem={item}
                        pageType={
                            SmartStorage.get('radioQueryData').pageType || ''
                        }
                        status={true}
                    ></TagName>
                );
            })}
        </div>
    );
};

export default DetailPage;
