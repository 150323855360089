import React, { useState, useEffect, useCallback } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    Radio,
    InputNumber,
    Tag,
    Button,
    message,
} from 'antd';
import './index.less';

import commonService from '@/service/common';

const SystemConfig = (props) => {
    const [tagsNew, setTags] = useState([]);
    const [tagsKeyWordNew, setKeyWordTags] = useState([]);
    const { ruleListConfigVisible, drawerData } = props;
    const [tenentOptions, setTenentOptions] = useState([]);
    const [form] = Form.useForm();
    const [inputValue, setInputValue] = useState('');
    const [loding, setLoding] = useState(false);
    const [inputKeyWordValue, setInputKeyWordValue] = useState('');
    const [rulueListTitle, setRulueListTitle] = useState('新增制度');
    console.log('drawerData', drawerData);
    const drawerDataformUpdates = () => {
        setRulueListTitle(drawerData.rulename ? '编辑水单制度' : '新增水单制度');

        // 处理菜品名称数组
        const normalizeArray = (data) =>
            typeof data === 'string' ? data.split(',') : Array.isArray(data) ? data : [];

        if (drawerData.dishesNames) {
            const dishesArray = normalizeArray(drawerData.dishesNames);
            setTags(dishesArray);
            form.setFieldsValue({ DishesList: dishesArray });
        }

        if (drawerData.keyWords) {
            const dishesKeyArray = normalizeArray(drawerData.keyWords);
            setKeyWordTags(dishesKeyArray);
            form.setFieldsValue({ KeyWordList: dishesKeyArray });
        }
        // setRulueListTitle(
        //     drawerData.rulename ? '编辑水单制度' : '新增水单制度',
        // );
        // // 处理菜品名称数组
        // if (drawerData.dishesNames) {
        //     const dishesArray =
        //         typeof drawerData.dishesNames === 'string'
        //             ? drawerData.dishesNames.split(',')
        //             : Array.isArray(drawerData.dishesNames)
        //             ? drawerData.dishesNames
        //             : [];
        //     setTags(dishesArray);
        //     form.setFieldsValue({ DishesList: dishesArray });
        // }
        // if (drawerData.keyWords) {
        //     const dishesKeyArray =
        //         typeof drawerData.keyWords === 'string'
        //             ? drawerData.keyWords.split(',')
        //             : Array.isArray(drawerData.keyWords)
        //             ? drawerData.keyWords
        //             : [];
        //         setKeyWordTags(dishesKeyArray);
        //     form.setFieldsValue({ KeyWordList: dishesKeyArray });
        // }
    };

    const handleTagClose = (removedTag, isKeyWord) => {
        const fieldName = isKeyWord ? 'KeyWordList' : 'DishesList';
        const setTagsFn = isKeyWord ? setKeyWordTags : setTags;

        const tags = form
            .getFieldValue(fieldName)
            .filter((tag) => tag !== removedTag);

        form.setFieldsValue({ [fieldName]: tags });
        setTagsFn(tags);
    };
    const handleChange = (value) => {
        form.setFieldsValue({ TenantIdList: value });
    };
    const handleRuleListCancel = () => {
        props.handleRuleListCancel('cancel');
    };
    const handleAddTag = (isKeyWord) => {
        console.log('inputValue', inputValue);
        console.log('tagsNew', tagsNew);
        const input = isKeyWord ? inputKeyWordValue : inputValue;
        const tags = isKeyWord ? tagsKeyWordNew : tagsNew;
        const setTagsFn = isKeyWord ? setKeyWordTags : setTags;
        const setInputFn = isKeyWord ? setInputKeyWordValue : setInputValue;
        const fieldName = isKeyWord ? 'KeyWordList' : 'DishesList';
        const warningMsg = isKeyWord ? '水单关键词标签' : '菜品分类关键词标签';

        if (!input) {
            message.warning(`请输入${warningMsg}再进行添加`);
            return;
        }

        if (tags.includes(input)) {
            message.warning(`不能重复添加${warningMsg}`);
            return;
        }

        const newTags = [...tags, input];
        form.setFieldsValue({ [fieldName]: newTags });
        setTagsFn(newTags);
        setInputFn('');
        // if(isKeyWord){
        //     if (inputKeyWordValue) {
        //         if (!tagsKeyWordNew.includes(inputKeyWordValue)) {
        //             console.log('inputValue2', inputKeyWordValue);
        //             const newTags = [...tagsKeyWordNew, inputKeyWordValue];
        //             form.setFieldsValue({
        //                 KeyWordList: newTags,
        //             });
        //             setKeyWordTags(newTags);
        //             setInputKeyWordValue('');
        //         } else {
        //             message.warning('不能重复添加水单关键词标签');
        //         }
        //     } else {
        //         message.warning('请输入水单关键词标签再进行添加');
        //     }
        // }else{
        //     if (inputValue) {
        //         if (!tagsNew.includes(inputValue)) {
        //             console.log('inputValue2', inputValue);
        //             const newTags = [...tagsNew, inputValue];
        //             form.setFieldsValue({
        //                 DishesList: newTags,
        //             });
        //             setTags(newTags);
        //             setInputValue('');
        //         } else {
        //             message.warning('不能重复添加菜品分类关键词标签');
        //         }
        //     } else {
        //         message.warning('请输入菜品分类关键词标签再进行添加');
        //     }
        // }   

    };
    const handleRuleListOk = () => {
        let cloneFormData = null;
        form.validateFields().then((values) => {
            cloneFormData = structuredClone(values);
            if (drawerData) {
                cloneFormData.id = drawerData.id;
            }
            setLoding(true);
            commonService.InstitutionRule(cloneFormData).then((res) => {
                if (res.success) {
                    props.handleRuleListCancel('ok');
                }
                setLoding(false);
            });
        });
    };
    const getTenentList = () => {
        let params = {
            // QueryId: 'aed0deda-59de-4a8f-9631-f1bdfbe44880', //全租户
            QueryId: 'a9023fd8-55c1-4f95-9e12-5d757bbc9a34',
            cluster: 'default',
            UsePaging: false,
            parameters: {},
        };
        commonService.getQueryData(params).then((res) => {
            if (res.success) {
                const tenantIds = drawerData?.tenantIds?.split(',') || [];
                const options = res.content.rows.map((item) => ({
                    label: item.name,
                    value: item.value,
                    disabled: tenantIds.includes(item.value)
                        ? false
                        : item.isUsed,
                }));

                setTenentOptions(options);

                if (tenantIds.length > 0) {
                    const selectedTenants = options
                        .filter((item) => tenantIds.includes(item.value))
                        .map((item) => item.value);

                    form.setFieldsValue({
                        TenantIdList: selectedTenants,
                    });
                }
            }
        });
    };
    const TimeDifferenceChange = (e) => {
        console.log(e.target.value)
        if (e.target.value == 1) {
            form.setFieldsValue({
                TimeDifference: 0,
            });
        }
    };
    const inputChange = (e,type) => {
        console.log(e)
        form.setFieldsValue({ [type]: e!=0?0:1 });
    }
    const TimeIntervalChange = (e) => {
        console.log(e.target.value);
        if (e.target.value == 1) {
            form.setFieldsValue({
                TimeInterval: 0,
            });
        }
    }
    const DishesPriceChange = (e) => {
        if (e.target.value == 1) {
            form.setFieldsValue({
                DishesPrice: 0,
            });
        }
    }
    const PeopleDifferenceChange = (e) => {
        if (e.target.value == 1) {
            form.setFieldsValue({
                PeopleDifference: 0,
            });
        }
    };
    useEffect(() => {
        drawerDataformUpdates();
    }, [drawerData]);
    useEffect(() => {
        getTenentList();
    }, []);
    return (
        <>
            <Modal
                title={rulueListTitle}
                destroyOnClose
                maskClosable={false}
                open={ruleListConfigVisible}
                width={800}
                onCancel={() => handleRuleListCancel('cancel')}
                onOk={(e)=>!loding&&handleRuleListOk()}
            >
                <div className="system-config-container">
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            RuleName: drawerData.rulename,
                            DishesList: [],
                            IsTimeDifferenceUnlimited:Object.keys(drawerData).length > 0 ?drawerData.isTimeDifferenceUnlimited:1,
                            TimeDifference: drawerData.timeDifference || 0,
                            IsTimeOrderUnlimited:
                            Object.keys(drawerData).length > 0 ?drawerData.isTimeOrderUnlimited : 1,
                            IsPeopleDifferenceUnlimited:
                            Object.keys(drawerData).length > 0?drawerData.isPeopleDifferenceUnlimited : 1,
                            PeopleDifference: drawerData.peopleDifference || 0,
                            IsRuleNumberDuplicationAllowed:
                                drawerData.isRuleNumberDuplicationAllowed || 1,
                            IsManualEntryAllowed:
                            Object.keys(drawerData).length > 0 ?drawerData.isManualEntryAllowed : 1,
                            TenantIdList: [],
                            IsMaxPriceDishesUnlimited: Object.keys(drawerData).length > 0?drawerData.isMaxPriceDishesUnlimited : 1,
                            DishesPrice: drawerData.dishesPrice || 0,
                            IsTimeIntervalUnlimited: Object.keys(drawerData).length > 0?drawerData.isTimeIntervalUnlimited : 1,
                            TimeInterval: drawerData.timeInterval || 0,
                            KeyWordList: [],
                            // IsDishesChangePriceUnlimited: drawerData.isDishesChangePriceUnlimited || 1,
                        }}
                    >
                        <Form.Item
                            label="制度名称"
                            name="RuleName"
                            rules={[
                                { required: true, message: '请输入制度名称' },
                                { max: 50, message: '制度名称长度不能超过50个字符' }
                            ]}
                        >
                            <Input placeholder="请输入制度名称" />
                        </Form.Item>

                        <Form.Item
                            label="租户"
                            name="TenantIdList"
                            rules={[{ required: true, message: '请选择租户' }]}
                        >
                            <Select
                                placeholder="请选择租户，可多选"
                                mode="multiple"
                                options={tenentOptions}
                                onChange={handleChange}
                            ></Select>
                        </Form.Item>
                        <div className="category-container">
                            <Form.Item
                                label="菜品分类键词"
                                name="DishesList"
                                className="category-item"
                            >
                                <div className="category-tags">
                                    {tagsNew.map((tag) => (
                                        <Tag
                                            key={tag}
                                            closable
                                            onClose={() => handleTagClose(tag)}
                                        >
                                            {tag}
                                        </Tag>
                                    ))}
                                </div>
                            </Form.Item>

                            <Form.Item noStyle>
                                <Input
                                    placeholder="每个类别词为一个单位"
                                    style={{ width: '200px' }}
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Button type="primary" onClick={(e) => handleAddTag()}>
                                添加
                            </Button>
                        </div>
                        <Form.Item
                            label="水单时间与支付时间间隔"
                            className="time-config"
                        >
                            <Form.Item name="IsTimeDifferenceUnlimited" noStyle>
                                <Radio.Group onChange={TimeDifferenceChange}>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={0}>
                                        水单时间与支付时间间隔：
                                        <Form.Item
                                            name="TimeDifference"
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                onChange={(e) =>inputChange(e,'IsTimeDifferenceUnlimited')}
                                                step={1}
                                                formatter={(value) =>
                                                    value
                                                        ? Number(value).toFixed(
                                                            2,
                                                        )
                                                        : ''
                                                }
                                            />
                                        </Form.Item>
                                        小时
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            label="水单时间顺序"
                            name="IsTimeOrderUnlimited"
                        >
                            <Radio.Group>
                                <Radio value={1}>不限</Radio>
                                <Radio value={0}>水单时间比支付时间晚</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="用餐人数"
                            name="IsPeopleDifferenceUnlimited"
                        >
                            <Radio.Group onChange={PeopleDifferenceChange}>
                                <Radio value={1}>不限</Radio>
                                <Radio value={0}>
                                    水单用餐人数与订单人数相差：
                                    <Form.Item name="PeopleDifference" noStyle>
                                        <InputNumber
                                            min={0}
                                            step={1}
                                            onChange={(e) =>inputChange(e,'IsPeopleDifferenceUnlimited')}
                                            formatter={(value) =>
                                                `${value}`.replace(/\D/g, '')
                                            }
                                            parser={(value) =>
                                                value.replace(/\D/g, '')
                                            }
                                        />
                                    </Form.Item>
                                    人
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        {/* <Form.Item
                            label="水单号"
                            name="IsRuleNumberDuplicationAllowed"
                        >
                            <Radio.Group>
                                <Radio value={1}>不限</Radio>
                                <Radio value={0}>不允许与历史水单号重复</Radio>
                            </Radio.Group>
                        </Form.Item> */}

                        <Form.Item label="是否手写" name="IsManualEntryAllowed">
                            <Radio.Group>
                                <Radio value={1}>不限</Radio>
                                <Radio value={0}>不允许手写</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="菜品单价上限"
                            className="time-config"
                        >
                            <Form.Item name="IsMaxPriceDishesUnlimited" noStyle>
                                <Radio.Group onChange={DishesPriceChange}>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={0}>
                                        菜品单价上限：
                                        <Form.Item
                                            name="DishesPrice"
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                                onChange={(e) =>inputChange(e,'IsMaxPriceDishesUnlimited')}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\D/g, '')
                                                }
                                                parser={(value) =>
                                                    value.replace(/\D/g, '')
                                                }
                                            />
                                        </Form.Item>
                                        元
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            label="水单开始时间与结束时间间隔"
                            className="time-config"
                        >
                            <Form.Item name="IsTimeIntervalUnlimited" noStyle>
                                <Radio.Group onChange={TimeIntervalChange}>
                                    <Radio value={1}>不限</Radio>
                                    <Radio value={0}>
                                        水单开始时间与结束时间间隔：
                                        <Form.Item
                                            name="TimeInterval"
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                step={1}
                                                onChange={(e) =>inputChange(e,'IsTimeIntervalUnlimited')}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\D/g, '')
                                                }
                                                parser={(value) =>
                                                    value.replace(/\D/g, '')
                                                }
                                            />
                                        </Form.Item>
                                        分钟
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                        <div className="category-container">
                            <Form.Item
                                label="水单关键词"
                                name="KeyWordList"
                                rules={[
                                    {
                                      required: true,
                                      message: '请添加水单关键词',
                                    },
                                  ]}
                                className="category-item"
                            >
                                <div className="category-tags">
                                    {tagsKeyWordNew.map((tag) => (
                                        <Tag
                                            key={tag}
                                            closable
                                            onClose={() => handleTagClose(tag, true)}
                                        >
                                            {tag}
                                        </Tag>
                                    ))}
                                </div>
                            </Form.Item>

                            <Form.Item noStyle>
                                <Input
                                    placeholder="每个关键词为一个单位"
                                    style={{ width: '200px' }}
                                    value={inputKeyWordValue}
                                    onChange={(e) => {
                                        setInputKeyWordValue(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Button type="primary" onClick={(e) => handleAddTag(true)}>
                                添加
                            </Button>
                        </div>
                        {/* <Form.Item label="菜品来回变价" name="IsDishesChangePriceUnlimited">
                            <Radio.Group>
                                <Radio value={1}>不限</Radio>
                                <Radio value={0}>是</Radio>
                            </Radio.Group>
                        </Form.Item> */}
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default SystemConfig;
