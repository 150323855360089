/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const HHKPay = (props) => {
  return (
    <div>
      <iframe
        style={{
          position: "absolute",
          height: "calc(100% - 110px)",
          width: "100%",
          border: "none",
          width: "calc(100% - 230px)",
          left: "230px"
        }}
        src={
          process.env.REACT_APP_HHKPAY||'https://engine.smartmice.cn'
        }
      ></iframe>
    </div>
  );
};

export default HHKPay;
