import { request } from './request';

const financeManageService = {
    //查询工作项
    QueryAccessNodes: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/QueryAccessNodes',
            params,
        );
    },
    //查询table头
    QueryFields: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/QueryFields',
            params,
        );
    },
    //同步
    SyncProcessData: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/SyncProcessData',
            params,
        );
    },
    //查询首页table数据
    PagingAccessTasks: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/PagingAccessTasks',
            params,
        );
    },
    //导出首页table数据{responseType: "arraybuffer"}
    Export: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/Export',
            params,
        );
    },
    //查询首页日志数据
    QueryAccessTaskLogs: (params) => {
        return request.post(
            '/api/foundation/bpm/foundation/workflow/v2/Task/QueryAccessTaskLogs',
            params,
        );
    },
    //查询搜索条件
    GetJGrid: (params) => {
        return request.post('/api/foundation/grid/GridSet/Get', params);
    },
    // 获取基本信息
    GetBaseDetail: (params) => {
        return request.post(
            '/api/finance/mice/Payment/GetPaymentDetail',
            params,
        );
    },
    GetTask: (params) => {
        return request.get(
            '/api/foundation/bpm/foundation/workflow/v2/Task/GetTask',
            { params: params },
        );
    },
    CheckPay: (params) => {
        return request.post('/api/finance/mice/Payment/CheckPay', params);
    },
    Payoff: (params) => {
        return request.post('/api/finance/mice/Payment/Payoff', params);
    },
    TransferAccount: (params) => {
        return request.post(
            '/api/finance/mice/Payment/TransferAccount',
            params,
        );
    },
    RebateTask: (params) => {
        return request.post('/api/jfinance/addOrUpdate/rebateTask', params);
    },
    QueryFiles: (params) => {
        return request.post('/api/paper/invoice/preparation/Query', params);
    },
    GetAction: (params) => {
        return request.post('/api/jfinance/rebateTask/getAction', params);
    },
    ColseTask: (params) => {
        return request.post('/api/jfinance/restoreOrClose/rebateTask', params);
    },
};

export default financeManageService;
